dmx.Component('socket', {

    initialData: {
        id: null,
        connected: false,
        disconnected: true
    },

    attributes: {
        namespace: {
            type: String,
            default: '/'
        },

        options: {
            type: Object,
            default: {}
        }
    },

    methods: {
        emit: function(eventName, data) {
            this.socket.emit(eventName, data);
        },

        connect: function() {
            this.socket.connect();
        },

        request: function(eventName, data) {
            var socket = this.socket;

            return new Promise(function(resolve) {
                socket.emit(eventName, data, resolve);
            });
        }
    },

    events: {
        connect: Event,
        disconnect: Event,
        connect_error: Event,
        message: Event
    },

    message: function(eventName, data) {
        this.dispatchEvent('message', {}, data, eventName);
    },

    trigger: function(eventName) {
        this.set('id', this.socket.id);
        this.set('connected', this.socket.connected);
        this.set('disconnected', this.socket.disconnected);
        this.dispatchEvent(eventName);
    },

    render: function(node) {
        if (node.textContent) {
            // for usage like a flow, a script tag with config as content
            try {
                this.cfg = (window.Hjson ? Hjson : JSON).parse(node.textContent);
            } catch (e) {
                console.error(e);
                if (dmx.debug) {
                    console.debug(node.textContent);
                }
            }
        }

        this.onConnect = this.trigger.bind(this, 'connect');
        this.onDisconnect = this.trigger.bind(this, 'disconnect');
        this.onConnectError = this.trigger.bind(this, 'connect_error');
        this.onMessage = this.message.bind(this);
        
        this.socket = dmx.Socket(this.props.namespace, this.props.options, this.cfg, this);
        this.socket.on('connect', this.onConnect);
        this.socket.on('disconnect', this.onDisconnect);
        this.socket.on('connect_error', this.onConnectError);
        this.socket.onAny(this.onMessage);
    },

    destroyed: function() {
        this.socket.off('connect', this.onConnect);
        this.socket.off('disconnect', this.onDisconnect);
        this.socket.off('connect_error', this.onConnectError);
        this.socket.offAny(this.onMessage);
    }

});