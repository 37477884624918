dmx.Actions({

    'sockets.emit': function(options) {
        options = this.parse(options);
        dmx.Socket(options.namespace || '/').emit(options.eventName, options.params);
    },

    'sockets.request': function(options) {
        options = this.parse(options);
        return new Promise(function(resolve) {
            dmx.Socket(options.namespace || '/').emit(options.eventName, options.params, resolve);
        });
    }

});