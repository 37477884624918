dmx.__sockets = {};

dmx.Socket = function(namespace, options, config, parent) {
    namespace = namespace || '/';
    options = dmx.extend(true, {
        transports: ['websocket'] // default to websocket transport only
    }, options);

    var socket = dmx.__sockets[namespace];

    if (!socket) {
        var domain = '';
        var scriptElm = document.querySelector('[src$="socket.io/socket.io.js"]');

        if (scriptElm) {
            var src = scriptElm.getAttribute('src');
            domain = src.replace(/\/?socket\.io\/socket\.io\.js$/, '');
        }

        socket = io(domain + namespace, options);

        if (options.transports[0] == 'websocket') {
            // on reconnection, reset the transports option, as the Websocket
            // connection may have failed (caused by proxy, firewall, browser, ...)
            socket.on('reconnect_attempt', function() {
                socket.io.opts.transports = ['polling', 'websocket'];
            });
        }
        dmx.__sockets[namespace] = socket;
    }

    if (config) {
        Object.keys(config).forEach(function(eventName) {
            socket.on(eventName, function(data, cb) {
                dmx.Flow.run(config[eventName], new dmx.DataScope({$param: data}, parent)).then(function(data) {
                    if (typeof cb == 'function') cb(data);
                });
            });
        });
    }

    return socket;
}